<template>
  <v-main :class="desktop ? 'tway-background-curves-svg' : 'tway-background-curves-svg-mobile-nologo'">
    <v-container class="registration-container" fill-height>
      <v-row v-if="mobile" justify="center" class="pa-0 ma-0">
        <v-img class="my-5 mb-10" :src="require('@/assets/img/byline.svg')" max-width="250px" />
      </v-row>
      <v-row justify="center">
        <v-col cols="12" align="center">
          <dti-ending-card />
        </v-col>
        <v-col cols="1"></v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import dtiEndingCard from "@/components/invitations/dtiEndingCard.vue";
export default {
  components: { dtiEndingCard },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>

<style lang="scss" scoped>
.register-column {
  p {
    font-size: 15px;
  }
}
.registration-card {
  max-width: 1100px;
}
.registration-container {
  display: flex;
  justify-content: center;
}
.divider {
  width: 100% !important;
}
.back {
  /* Link button RC 16 */

  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  text-decoration-line: underline;
}
</style>
