<template>
  <v-card
    outlined
    :class="'complete-card mb-5' + (mobile ? 'pa-15' : 'mt-10')"
    style="border-radius: 10px; min-width: 305px; max-width: 700px"
  >
    <template>
      <v-row justify="center" class="mt-5">
        <v-col cols="12" align="center">
          <v-img
            :src="require('@/assets/img/invited/winners.jpg')"
            :max-width="mobile ? '270px' : '400px'"
            max-height="272px"
            class="mt-7"
          />
        </v-col>
        <v-col align="center" cols="12">
          <div
            class="mb-4 px-4"
            style="
              font-family: 'Josefin Sans';
              font-style: normal;
              font-weight: 400;
              font-size: 35px;
              line-height: 150%;
              text-align: center;
              color: #333333;
            "
          >
            <p :style="mobile ? 'font-size: 25px' : 'font-size: 35px'">
              ¡Completaste la Encuesta de Transformación Digital con éxito!
            </p>
          </div>
        </v-col>
        <v-col align="center" cols="12">
          <div
            style="
              font-family: 'Roboto Condensed';
              font-style: normal;
              font-weight: 400;
              line-height: 34px;
              text-align: center;
              color: #333333;
            "
            class="px-6"
          >
            <p :style="mobile ? 'font-size: 19px' : 'font-size: 25px'">
              Estos resultados orientarán a tu empresa sobre sus brechas digitales para activar un plan de trabajo en
              Tway que hará más competitivo su negocio.
            </p>
            <p class="mt-4" :style="mobile ? 'font-size: 19px' : 'font-size: 26px'">¡Gracias por tu participación!</p>
          </div>
        </v-col>
      </v-row>
      <v-row class="ma-0 py-10" justify="center">
        <v-col cols="12" align="center"
          ><a href="https://twaydigital.com/" style="text-decoration: none; text-transform: uppercase" class="">
            <v-btn
              depressed
              rounded
              color="tway-violet"
              class="button-text white--text"
              height="50px"
              :width="mobile ? '280px' : '480px'"
              :style="mobile ? 'font-size: 13px' : 'font-size: 20px'"
            >
              <span> Conoce más sobre transformación </span>
            </v-btn>
          </a></v-col
        >
      </v-row>
    </template>
  </v-card>
</template>

<script>
export default {
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>
